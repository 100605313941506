import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const BottomBorder = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 20px',
        backgroundColor: 'black',
        color: 'white',
        fontFamily: 'Noto Sans, sans-serif',
        fontSize: '0.9rem',
      }}
    >
      {/* Left Section */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          textAlign: 'left',
          flex: 1, // Makes this section flexible
        }}
      >
        Act One <span style={{ fontWeight: 'normal' }}>hair salon</span>
      </Typography>

      {/* Center Section (Description) */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 2,
          paddingLeft: '0.5rem'
        }}
      >
        <Typography
          variant="body2"
          sx={{
            lineHeight: 1.5,
            textAlign: 'left', // Aligns the text to the left
            maxWidth: '600px', // Keeps the text constrained
            fontSize: '0.65rem',
          }}
        >
          Please contact us at least 24 hrs in advance if you need to cancel or
          reschedule your appointment. <br />
          Note - we have free parking behind the salon.
        </Typography>
      </Box>

      {/* Right Section (Icons) */}
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        {/* Instagram Icon */}
        <a
          href="https://www.instagram.com/act1hairsalon"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '1.5rem' }} />
        </a>

        {/* Yelp Icon */}
        <a
          href="https://www.yelp.com/biz/act-one-hair-salon-eastchester"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <FontAwesomeIcon icon={faYelp} style={{ fontSize: '1.5rem' }} />
        </a>

        {/* Location Icon */}
        <a
          href="https://www.google.com/maps/place/226+Main+St,+Eastchester,+NY+10709"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: '1.5rem' }} />
        </a>
      </Box>
    </Box>
  );
};

export default BottomBorder;
