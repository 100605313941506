import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";

const images = [
  "/images/caro1.png",
  "/images/caro2.png",
  "/images/caro3.png",
  "/images/caro4.png",
];

const GalleryComponent = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const getClassesForImages = () => {
    const numImages = images.length;

    return images.map((_, index) => {
      const relativeIndex = (index - currentImage + numImages) % numImages;

      if (relativeIndex === 0) return "center";
      if (relativeIndex === 1) return "right";
      if (relativeIndex === numImages - 1) return "left";
      if (relativeIndex === 2) return "far-right";
      if (relativeIndex === numImages - 2) return "far-left";
      return "";
    });
  };

  const imageClasses = getClassesForImages();

  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    setTimeout(() => setIsAnimating(false), 500);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        py: 4,
        position: "relative",
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontFamily: "Noto Sans, sans-serif",
          textAlign: "center",
          mb: 1,
          fontSize: { xs: "1rem", md: "1.5rem" }, // Matches ReviewsCarousel title size
        }}
      >
        Explore our work
      </Typography>

      {/* Caption */}
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          color: "black",
          fontFamily: "Noto Sans, sans-serif",
          mb: 2,
          mx: "auto",
          maxWidth: "600px",
          fontSize: { xs: "0.75rem", md: "0.9rem" }, // Matches ReviewsCarousel caption size
        }}
      >
        We have a well-rounded team of highly skilled stylists.
      </Typography>

      {/* Carousel */}
      <Box className="carousel">
        <IconButton
          onClick={handlePrev}
          className="carousel-button left MuiIconButton-root"
          aria-label="Previous"
        >
          <ArrowBackIosIcon className="carousel-icon" />
        </IconButton>

        {images.map((src, index) => (
          <Box
            key={index}
            component="img"
            src={src}
            alt={`Image ${index + 1}`}
            className={`carousel-image ${imageClasses[index]}`}
          />
        ))}

        <IconButton
          onClick={handleNext}
          className="carousel-button right MuiIconButton-root"
          aria-label="Next"
        >
          <ArrowForwardIosIcon className="carousel-icon" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default GalleryComponent;
