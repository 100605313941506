import React from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery, useTheme } from '@mui/material';

const BackgroundComponent = ({ onLinkClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: isMobile ? '80vh' : '100vh',
        width: '100vw',
        backgroundColor: 'black', // Black background for sides
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden', // Prevent overflow
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: isMobile ? '80vh' : '100vh', // Crop bottom of the image on mobile
          width: '100vw',
          backgroundImage: 'url("/images/background.png")',
          backgroundSize: 'cover', // Maintain aspect ratio
          backgroundPosition: isMobile ? 'center top' : 'center 30%', // Ensure proper cropping
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', // Space between sections
          filter: 'grayscale(100%)',
          paddingTop: 20,
          maxWidth: { xs: '100%', md: '1200px' }, // Keep original maxWidth
          margin: { xs: '0', md: '0 auto' }, // Center the component for desktop
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay for readability
            zIndex: 1,
          },
        }}
      >
        {/* Top Navigation Bar */}
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            left: 0,
            right: { xs: 0, md: 20 },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 2,
            paddingLeft: { xs: '20px', md: '40px' },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#ffffff',
              fontWeight: 'bold',
              fontFamily: 'Noto Sans, sans-serif',
              fontSize: { xs: '1.2rem', md: '2rem' },
            }}
          >
            Act One {isMobile && <br />}
            <span style={{ fontWeight: 'normal' }}>hair salon</span>
          </Typography>

          <Box>
            {['Services', 'Gallery', 'Reviews', 'About'].map((link) => (
              <Link
                key={link}
                onClick={() => onLinkClick[link.toLowerCase()]()}
                color="inherit"
                underline="none"
                sx={{
                  color: '#ffffff',
                  mx: { xs: 1, md: 2 },
                  fontSize: { xs: '0.9rem', md: '1rem' },
                  fontFamily: 'Noto Sans, sans-serif',
                  cursor: 'pointer',
                }}
              >
                {link}
              </Link>
            ))}
          </Box>
        </Box>

        {/* Left-aligned Text Content */}
        <Box
          sx={{
            zIndex: 2,
            maxWidth: '500px',
            textAlign: 'left',
            marginTop: { xs: '10vh', md: '20vh' },
            paddingLeft: { xs: '20px', md: '40px' },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: '#ffffff',
              fontFamily: 'Noto Sans, sans-serif',
              fontSize: { xs: '1.5rem', md: '2.5rem' },
            }}
          >
            Elevating your style with personalized care
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#ffffff',
              marginTop: 2,
              fontFamily: 'Noto Sans, sans-serif',
              fontSize: { xs: '0.9rem', md: '1.25rem' },
            }}
          >
            Call us to book an appointment today.
          </Typography>
          {/* View Service List Button */}
          <Button
            onClick={() => onLinkClick['services']()} // Navigates to Services
            variant="contained"
            sx={{
              backgroundColor: '#ffffff',
              color: '#000000',
              marginTop: 3,
              textTransform: 'none',
              padding: '10px 20px',
              borderRadius: '8px',
              fontSize: { xs: '0.9rem', md: '1rem' },
              fontFamily: 'Noto Sans, sans-serif',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            View service list
          </Button>
        </Box>

        {/* Centered Contact Info at Bottom */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 20,
            left: { xs: '20px', md: '40px' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'flex-start',
            gap: { xs: 1, md: 3 },
            zIndex: 2,
            color: '#ffffff',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '0.9rem',
          }}
        >
          <a
              href="tel:914-779-8455"
              style={{
                color: 'inherit',
                textDecoration: 'none',
                fontFamily: 'Noto Sans, sans-serif',
                fontSize: '0.9rem',
              }}
            >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 1, md: 0 } }}>
              <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: 8 }} />
              914-779-8455
            </Box>
          </a>
          <a
            href="https://www.google.com/maps/place/226+Main+St,+Eastchester,+NY+10709"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
            226 Main St. Eastchester, NY 10709
          </Box>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default BackgroundComponent;
