import React, { useState, useEffect } from "react";
import teamMembers from "./teamMembers.json";
import { Box, Typography } from "@mui/material";
import "./index.css";

// Define an enum for the layout types
const LayoutType = {
  ONE_COL: "one_col",
  TWO_COL: "two_col",
  THREE_COL: "three_col",
};

const Team = () => {
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [layout, setLayout] = useState(LayoutType.THREE_COL); // Default to three-column layout

  // Handle responsive layout
  useEffect(() => {
    const updateLayout = () => {
      if (window.innerWidth <= 720) {
        setLayout(LayoutType.ONE_COL);
      } else if (window.innerWidth <= 1090) {
        setLayout(LayoutType.TWO_COL);
      } else {
        setLayout(LayoutType.THREE_COL);
      }
    };

    updateLayout(); // Initial check
    window.addEventListener("resize", updateLayout);
    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  const toggleExpand = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index)
        ? prev.filter((i) => i !== index) // Collapse if already expanded
        : [...prev, index] // Expand if not already expanded
    );
  };

  let columns = [[], [], []];

  if (layout === LayoutType.TWO_COL) {
    // Reorder members for two-column layout by preserving row order
    const reordered = [];
    for (let i = 0; i < teamMembers.length; i += 3) {
      if (teamMembers[i]) reordered.push(teamMembers[i]); // First column of three-column layout
      if (teamMembers[i + 1]) reordered.push(teamMembers[i + 1]); // Second column
      if (teamMembers[i + 2]) reordered.push(teamMembers[i + 2]); // Third column
    }

    // Split reordered array into two columns
    columns = [[], []];
    reordered.forEach((member, index) => {
      columns[index % 2].push(member);
    });
  } else if (layout === LayoutType.ONE_COL) {
    // Reorder members into a single column by preserving row order
    columns = [teamMembers]; // Place all members into one column
  } else {
    // Original three-column layout
    teamMembers.forEach((member, index) => {
      columns[index % 3].push(member); // Distribute across three columns
    });
  }

  return (
    <Box
      sx={{
        maxWidth: "100%", // Ensure the container spans the available width
        padding: "0 16px", // Add consistent horizontal padding
        boxSizing: "border-box", // Include padding in width calculation
      }}
    >
      <Box
        sx={{
          maxWidth: "1200px", // Matches the grid's width
          margin: "0 auto", // Centers the title and caption
          padding: "0 0px", // Adds consistent padding
          boxSizing: "border-box",
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontFamily: "Noto Sans, sans-serif",
            textAlign: "left",
            mb: 1,
            fontSize: { xs: "1rem", md: "1.5rem" },
          }}
        >
          Our team
        </Typography>

        {/* Caption */}
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: "Noto Sans, sans-serif",
            textAlign: "left",
            color: "black",
            mb: 2,
            fontSize: { xs: "0.75rem", md: "0.9rem" },
            maxWidth: "100%",
            whiteSpace: "normal",
            overflowWrap: "break-word",
          }}
        >
          If you would like to book with a specific stylist, please call the number on their contact card.
        </Typography>
      </Box>

      {/* Team Grid */}
      <div className="team-grid">
        {columns.map((column, colIndex) => (
          <div key={colIndex} className="team-column">
            {column.map((member, index) => {
              const originalIndex = teamMembers.indexOf(member);
              const hasDescription = Boolean(member.description);
              return (
                <div
                  key={originalIndex}
                  className={`team-card ${
                    !hasDescription ? "no-description" : ""
                  } ${expandedIndices.includes(originalIndex) ? "expanded" : ""}`}
                  onClick={() => hasDescription && toggleExpand(originalIndex)}
                >
                  <div className="team-info">
                    <div className="team-left">
                      <h2>{member.name}</h2>
                      {member.phone && (
                        <a
                          href={`tel:${member.phone}`}
                          onClick={(e) => e.stopPropagation()} // Prevent triggering the card expansion
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            fontFamily: "Noto Sans, sans-serif",
                            fontSize: "0.9rem",
                          }}
                        >
                          <p>
                            <i className="phone-icon"></i> {member.phone}
                          </p>
                        </a>
                      )}
                    </div>
                    <div className="team-title">{member.title}</div>
                  </div>

                  {expandedIndices.includes(originalIndex) &&
                    member.description && (
                      <div className="team-description">
                        {member.description}
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default Team;
