import React from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// Continuous scrolling animation
const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const reviews = [
  "They were able to accommodate me last minute on a Saturday afternoon. Love how my haircut turned out.\"",
  "The owner and staff are amazing!\"",
  "An absolute pleasure. Everyone in there is amazing at what they do. They really care about how you want your hair colored, cut, and styled.\"",
  "I wear my hair both straight and curly so it’s tough to get the right cut for both, but my hair looks absolutely fabulous.\"",
  "Great ownership and people who do excellent work. You don’t leave the chair until you are happy.\"",
  "Fantastic salon. Owner is amazing and very reasonable for amazing work! Highly recommend it!\"",
];

const ReviewsCarousel = () => {
  const reviewWidth = 400; // Increased width of each review card
  const gap = 24; // Adjusted spacing between cards
  const totalWidth = (reviewWidth + gap) * reviews.length;

  return (
    <Box
      sx={{
        overflow: "hidden", // Hide overflowing content
        width: "100%",
        py: 4,
        position: "relative",
      }}
    >
      {/* Header */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontFamily: "Noto Sans, sans-serif",
          textAlign: "center",
          mb: 1, // Reduced gap
          fontSize: { xs: "1rem", md: "1.5rem" }, // Smaller title
        }}
      >
        Words from our community
      </Typography>

      {/* Caption */}
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center", // Consistent alignment
          color: "black",
          fontFamily: "Noto Sans, sans-serif",
          mb: 0.5, // Reduced space below the caption
          mx: "auto", // Centers it horizontally
          maxWidth: "600px", // Limits the width to align with the title
          fontSize: { xs: "0.75rem", md: "0.9rem" }, // Adjusts size
        }}
      >
        We pride ourselves on treating each of our clients like a close friend.
      </Typography>

      {/* Black Stars */}
      <Box sx={{ textAlign: "center", mb: 1 }}> {/* Reduced space between stars and reviews */}
        {[...Array(5)].map((_, i) => (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            style={{
              color: "#000000",
              margin: "0 3px",
              fontSize: "0.8rem", // Smaller stars
              marginBottom: "1rem"
            }}
          />
        ))}
      </Box>

      {/* Reviews Scrolling */}
      <Box
        sx={{
          display: "flex",
          animation: {
            xs: `${scrollAnimation} 80s linear infinite`, // 20 seconds on mobile view
            md: `${scrollAnimation} 40s linear infinite`, // 40 seconds on larger screens
          },
          width: `${totalWidth * 2}px`, // Double the width for seamless scrolling
          gap: `${gap}px`,
        }}
      >

        {/* Original Reviews */}
        {reviews.map((review, index) => (
          <Box
            key={`original-${index}`}
            sx={{
              minWidth: `${reviewWidth}px`,
              maxWidth: `${reviewWidth}px`,
              p: 2,
              backgroundColor: "#ffffff",
              boxShadow: 3,
              borderRadius: "8px",
              display: "inline-block",
              textAlign: "left",
              fontFamily: "Noto Sans, sans-serif",
              fontSize: { xs: "0.9rem", md: "1rem" },
              lineHeight: "1.6",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: { xs: "3rem", md: "4rem" }, // Curved quotes
                fontFamily: "'Lora', 'Georgia', serif", // Stylized serif font
                lineHeight: "1",
                mb: -1,
              }}
            >
              “
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "normal",
                fontSize: { xs: "0.875rem", md: "1rem" },
              }}
            >
              {review}
            </Typography>
          </Box>
        ))}

        {/* Duplicated Reviews */}
        {reviews.map((review, index) => (
          <Box
            key={`duplicate-${index}`}
            sx={{
              minWidth: `${reviewWidth}px`,
              maxWidth: `${reviewWidth}px`,
              p: 2,
              backgroundColor: "#ffffff",
              boxShadow: 3,
              borderRadius: "8px",
              display: "inline-block",
              textAlign: "left",
              fontFamily: "Noto Sans, sans-serif",
              fontSize: { xs: "0.9rem", md: "1rem" },
              lineHeight: "1.6",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: { xs: "3rem", md: "4rem" }, // Curved quotes
                fontFamily: "'Lora', 'Georgia', serif", // Stylized serif font
                lineHeight: "1",
                mb: -1,
              }}
            >
              “
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "normal",
                fontSize: { xs: "0.875rem", md: "1rem" },
              }}
            >
              {review}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReviewsCarousel;
