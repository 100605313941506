import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const services = [
  {
    category: "Haircuts",
    items: [
      { name: "Women’s cut", price: "" },
      { name: "Men’s cut", price: "" },
      { name: "Curly cut", price: "" },
      { name: "Children’s cut", price: "" },
    ],
  },
  {
    category: "Hair treatments & styling",
    items: [
      { name: "Blowout", price: "" },
      { name: "Blowout with curls", price: "" },
      { name: "Perm", price: "" },
      { name: "Deep condition", price: "" },
      { name: "Olaplex", price: "" },
      { name: "B3 treatment", price: "" },
      { name: "Keratin", price: "" },
    ],
  },
  {
    category: "Color*",
    items: [
      { name: "Single process coloring", price: "" },
      { name: "Men’s color", price: "" },
      { name: "Gloss", price: "" },
      { name: "Color / glaze", price: "" },
    ],
  },
  {
    category: "Highlights*",
    items: [
      { name: "Partial", price: "" },
      { name: "Half head", price: "" },
      { name: "Full head", price: "" },
      { name: "Lowlights", price: "" },
      { name: "Face-framing highlights", price: "" },
      { name: "Balayage", price: "" },
      { name: "Ombre", price: "" },
    ],
  },
  {
    category: "Eyebrow treatment",
    items: [
      { name: "Eyebrow tinting", price: "" },
      { name: "Permanent makeup", price: "" },
    ],
  },
  {
    category: "Events",
    items: [
      { name: "Bridal hair", price: "" },
      { name: "Bridal hair trial", price: "" },
      { name: "Formal style & updo", price: "" },
      { name: "Children’s formal style & updo", price: "" },
    ],
  },
];

const ServiceList = () => {
  return (
    <Box
      sx={{
        maxWidth: "500px",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontFamily: "Noto Sans, sans-serif",
          textAlign: "left",
          mb: 1, // Matches ReviewsCarousel title spacing
          fontSize: { xs: "1rem", md: "1.5rem" }, // Matches ReviewsCarousel title size
        }}
      >
        Service List
      </Typography>

      {/* Caption */}
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: "Noto Sans, sans-serif",
          textAlign: "left",
          color: "black",
          mb: 2, // Matches ReviewsCarousel caption spacing
          fontSize: { xs: "0.75rem", md: "0.9rem" }, // Matches ReviewsCarousel caption size
        }}
      >
        We offer a variety of services, from quick cuts and coloring to formal hairstyling. For pricing, please contact us directly.
      </Typography>

      {/* Services Accordion */}
      {services.map((service, index) => (
        <Box key={index}>
          <Accordion
            elevation={0}
            sx={{
              "&:before": { display: "none" },
              boxShadow: "none",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                paddingLeft: "0px",
                fontFamily: "Noto Sans, sans-serif",
                fontWeight: "normal",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Noto Sans, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {service.category}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {service.items.map((item, idx) => (
                <Box
                  key={idx}
                  display="flex"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Typography sx={{ fontFamily: "Noto Sans, sans-serif" }}>
                    {item.name}
                  </Typography>
                  <Typography sx={{ fontFamily: "Noto Sans, sans-serif" }}>
                    {item.price}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}

      {/* Footer Notes */}
      <Box sx={{ textAlign: "left", marginTop: 2, color: "textSecondary" }}>
        <Typography
          variant="caption"
          sx={{ fontFamily: "Noto Sans, sans-serif" }}
        >
          *We carry organic color and products
        </Typography>
        <br />
        <Typography
          variant="caption"
          sx={{ fontFamily: "Noto Sans, sans-serif" }}
        >
        </Typography>
      </Box>
    </Box>
  );
};

export default ServiceList;
