import React, { useRef } from "react";
import BackgroundComponent from "./components/BackgroundComponent";
import ServiceList from "./components/ServiceList";
import GalleryComponent from "./components/GalleryComponent";
import ReviewsCarousel from "./components/ReviewsCarousel";
import AboutMe from "./components/AboutMe";
import TeamContacts from "./components/TeamContacts";
import BottomBorder from './components/BottomBorder';

function App() {
  const serviceRef = useRef(null);
  const galleryRef = useRef(null);
  const reviewsRef = useRef(null);
  const aboutRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="container">
      <BackgroundComponent
        onLinkClick={{
          services: () => scrollToSection(serviceRef),
          gallery: () => scrollToSection(galleryRef),
          reviews: () => scrollToSection(reviewsRef),
          about: () => scrollToSection(aboutRef),
        }}
      />
      <div ref={serviceRef}>
        <ServiceList />
      </div>
      <div ref={galleryRef}>
        <GalleryComponent />
      </div>
      <div ref={reviewsRef}>
        <ReviewsCarousel />
      </div>
      <div ref={aboutRef}>
        <AboutMe />
      </div>
      <TeamContacts />
      <BottomBorder />
    </div>
  );
}

export default App;
