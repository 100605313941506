import React from "react";
import { Box, Typography } from "@mui/material";

const AboutMe = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          width: "100%",
          maxWidth: 300,
          height: 350, // Set a fixed height to control the cropping
          borderRadius: 3,
          overflow: "hidden",
          position: "relative", // Enable positioning of the image
          left: { md: "-60px", xs: "0px" }, // Shift container to the left for larger view
        }}
      >
        <Box
          component="img"
          src="/images/profile.png"
          alt="Patty"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top", // Focus on the top of the image for cropping
            filter: "grayscale(100%)",
          }}
        />
      </Box>


      {/* Text Section */}
      <Box sx={{ ml: { md: 4 }, mt: { xs: 4, md: 0 } }}>
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontFamily: "Noto Sans, sans-serif",
            mb: 2,
            fontSize: { xs: "1rem", md: "1.5rem" }, // Matches ReviewsCarousel title size
          }}
        >
          About me
        </Typography>

        {/* Paragraphs */}
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: "Noto Sans, sans-serif",
            fontSize: { xs: "0.875rem", md: "1rem" }, // Matches ReviewsCarousel body size
            lineHeight: 1.6,
          }}
        >
          Hello! My name is Patty, and I began my journey with Act One Hair Salon
          when I was 17 years old. I took over the business from my father-in-law in
          1999 and worked tirelessly to establish myself over the past 25 years.
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: "Noto Sans, sans-serif",
            fontSize: { xs: "0.875rem", md: "1rem" },
            lineHeight: 1.6,
          }}
        >
          I center my business around integrity, attention to detail, and respect.
          I strive to make my clients feel heard. Our team is committed to our
          community, and we happily welcome new clients.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Noto Sans, sans-serif",
            fontSize: { xs: "0.875rem", md: "1rem" },
            lineHeight: 1.6,
          }}
        >
          We look forward to styling you!
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutMe;
